<template>
  <div class="location" v-if="currentLocation">
    <div
      class="about-wrapper location-panel"
      v-bind:class="{ active: tabControls.getCurrentTab() === 1 }"
    >
      <div class="panel-inner">
        <div class="hero">
          <img
            src="@/assets/images/location-hero-bg.png"
            alt=""
            v-prlx.mobile="{ reverse: true }"
            class="prlx-img"
          />
          <img
            :src="getImgUrl(currentLocation.slug, 'hero')"
            alt=""
            class="prlx-img location-image"
            v-prlx.mobile="{ limit: { min: -30, max: 100 }, reverse: true }"
          />
        </div>
        <img
          :src="getImgUrlLarge(currentLocation.slug, 'hero')"
          alt=""
          class="desktop-hero-img"
        />
        <div
          class="hero-text about-panel content"
          v-prlx.mobile="{ limit: { min: -30, max: 100 } }"
        >
          <h2>{{ currentLocation.category }}</h2>
          <h1>{{ currentLocation.title }}</h1>

          <div style="margin-left: -60px; padding-top: 0px">
            <DownArrow />
          </div>
        </div>
        <HornimanMuseum v-if="currentLocation.slug === '01-horniman-museum'" />
        <Barbican v-if="currentLocation.slug === '02-barbican'" />
        <Culpeper v-if="currentLocation.slug === '03-culpeper'" />
        <Kyoto v-if="currentLocation.slug === '04-kyoto'" />
        <Isabella v-if="currentLocation.slug === '05-isabella'" />
        <HillyFields v-if="currentLocation.slug === '06-hillyfields'" />
        <CrystalPalace v-if="currentLocation.slug === '07-crystalpalace'" />
        <Goldershill v-if="currentLocation.slug === '08-goldershill'" />
        <Wormwood v-if="currentLocation.slug === '09-wormwood'" />
        <NorthGreenwich v-if="currentLocation.slug === '10-northgreenwich'" />
        <OsterleyPark v-if="currentLocation.slug === '11-osterleypark'" />
        <WoodberryWetlands
          v-if="currentLocation.slug === '12-woodberrywetlands'"
        />
        <Haggerston v-if="currentLocation.slug === '13-haggerston'" />
        <BoilerGarden v-if="currentLocation.slug === '14-boilergarden'" />
        <GaiasGarden v-if="currentLocation.slug === '15-gaiasgarden'" />
      </div>
    </div>
    <div
      class="listen-wrapper location-panel"
      v-bind:class="{ active: tabControls.getCurrentTab() === 2 }"
    >
      <div class="panel-inner">
        <div class="listen">
          <div class="hero-text content">
            <div class="listen-title-text">
              <h2>{{ currentLocation.category }}</h2>
              <h1>{{ currentLocation.title }}</h1>
            </div>
            <div class="spotify-embed">
              <iframe
                width="100%"
                height="300"
                :src="currentLocation.embed"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="info-wrapper location-panel"
      v-bind:class="{ active: tabControls.getCurrentTab() === 3 }"
    >
      <div class="panel-inner">
        <div class="info">
          <div class="hero-text content">
            <h2>{{ currentLocation.category }}</h2>
            <h1>{{ currentLocation.title }}</h1>
            <img :src="getMapAsset(currentLocation.slug)" alt="map" />
            <p class="gmaps-link">
              <a
                :href="'http://maps.google.com/?q=' + currentLocation.address"
                target="_blank"
                class="btn"
                >Open in Google Maps</a
              >
            </p>
            <p class="gmaps-link">
              <a
                :href="currentLocation.walkingRoute"
                target="_blank"
                class="btn"
                >Find nearby walking route</a
              >
            </p>
          </div>

          <div class="info-panel">
            <h4>Location</h4>
            <p>{{ currentLocation.address }}</p>

            <h4>Tube</h4>
            <p>{{ currentLocation.tube_stops }}</p>

            <h4>Opening Times</h4>
            <p v-html="currentLocation.opening_times"></p>
          </div>
        </div>
      </div>
    </div>
    <footer class="pre-footer">
      <div class="wrapper">
        <h5>#RoamAndExplore</h5>
        <h4><span>Share</span><br />{{ currentLocation.title }}</h4>
        <div class="share">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://www.sonosroamandexplore.com"
            ><img src="@/assets/images/social-fb.svg" alt="Facebook"
          /></a>
          <a
            href="https://twitter.com/intent/tweet?text=Sonos%20Roam%20and%20Explore%3A%20https%3A%2F%2Fwww.sonosroamandexplore.com"
            ><img src="@/assets/images/social-tw.svg" alt="Twitter"
          /></a>
        </div>

        <h4><span>Discover more locations</span></h4>
        <div class="next-prev" v-if="nextLocation && previousLocation">
          <div class="prev" v-if="$route.params.slug != previousLocation.slug">
            <a
              :href="
                '/' +
                $router.resolve({
                  name: 'Location',
                  params: { slug: previousLocation.slug },
                }).href
              "
              class="btn"
              >Previous<br /><small>{{ previousLocation.title }}</small></a
            >
          </div>
          <div class="next" v-if="$route.params.slug != nextLocation.slug" v-on:click="window.scrollTo(0,0)">
            <a
              :href="
                '/' +
                $router.resolve({
                  name: 'Location',
                  params: { slug: nextLocation.slug },
                }).href
              "
              class="btn"
              >Next<br /><small>{{ nextLocation.title }}</small></a
            >
          </div>
        </div>
        <p style="margin: 0">
          <router-link to="/" class="map-return">Return to Map</router-link>
        </p>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
.next-prev {
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 15px auto 30px auto;
  justify-content: center;
  .prev,
  .next {
    width: 50%;
    text-align: center;
    color: white;
    .btn {
      width: 60%;
      height: 100%;
      display: block;
      margin:auto;
      padding: 10px 25px 0;
      small {
        display: block;
        padding-top: 2px;
        font-size: 10px;
      }
    }
  }
}
.map-return {
  color: white;
  text-decoration: underline;
}
.location-panel {
  display: none;
  &.active {
    display: block;
  }
}
.location {
  .walking-btn {
    border-color: #4e7954;
  }
  margin-top: -30px;
  .hero {
    .prlx-img {
      position: absolute;
      top: 0;
    }
    @media only screen and (min-width: 600px) {
      display: none;
    }
  }
  .content {
    color: #4e7954;
    h2 {
      color: #4e7954;
      font-size: 1.4em;
      line-height: 1.2;
      margin: 50px 0 10px 0;
    }
    a {
      color: #4e7954;
    }
  }
  .player {
    padding-top: 20px;
    text-align: center;
    p {
      display: inline-block;
      color: white;
      text-align: center;
      padding: 10px 25px 30px 25px;
      margin: 20px auto;
    }
    iframe {
      margin-top: -45px;
    }
  }
  .hero-text {
    margin-top: 149px;
    min-height: 45vh;
    color: white;
    @media only screen and (min-width: 600px) {
      margin-top: 0;
      padding-top: 130px;
      transform: none !important; //disable paralax
    }
    h2 {
      color: #bcd2a6;
      margin: 0;
      font-size: 2.4em;
    }
    h1 {
      font-size: 2.4em;
      margin: 0 0 20px 0;
      max-width: 270px;
      line-height: 1.1;
    }
    p {
      max-width: 250px;
    }
  }

  .content-intro {
    margin-top: 100px;
    margin-bottom: 70px;

    h3 {
      padding-top: 50px;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.2;
    }
  }
  blockquote {
    background-image: url(../assets/images/blockquote-pattern.png);
    background-position: top left;
    background-size: auto 100%;
    background-repeat: no-repeat;
    padding: 80px 0 20px 80px;
    color: white;
    font-size: 1.8em;
    line-height: 1.2;
    max-width: 280px;
    font-weight: 500;
    @media only screen and (min-width: 600px) {
      background: none;
      margin-left: 150px;
      margin-bottom: 20px;
      padding: 20px;
      div {
        /* disable parallax */
        transition: none !important;
        transform: none !important;
      }
    }
  }
  .tile {
    position: relative;
    @media only screen and (min-width: 600px) {
      display: none;
    }
    margin-bottom: 30px;
  }
  .tile-lower {
    margin-top: 40px;
  }
  .prlx-tile-bg {
    position: absolute;
    top: -40px;
  }
  .prlx-tile-img {
    width: 100%;
    margin-left: auto;
  }
  .prlx-tile-lower-graphic {
    margin-top: 2%;
  }
  .highlights {
    padding-left: 50px;
    margin-top: -60%;
    margin-bottom: 45%;
    color: white;
    h3 {
      color: #bcd2a6;
    }
    ul {
      margin-top: 10px;
      padding-left: 20px;
      list-style: none;
      li {
        margin-bottom: 15px;
        font-size: 1.2em;
        line-height: 1.2;
        font-weight: 500;
        max-width: 250px;
      }
    }
  }
  .listen {
    background-image: url(../assets/images/location-listen-bg.png);
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
    padding: 177px 0 50px 0;
    @media only screen and (min-width: 600px) {
      background-image: url(../assets/images/location-listen-bg-lg.png);
      background-position: 0 27%;
      background-size: 60%;
      .hero-text {
        display: flex;
        width: 60%;
        .listen-title-text {
          width: 400px;
          padding-top: 3px;
        }
        .spotify-embed {
          margin-left: 50px;
        }
      }
    }
    .hero-text {
      margin-top: 0;
      p {
        max-width: 100%;
        color: white;
      }
    }
  }
  .info {
    padding: 177px 0 0 0;
    .hero-text {
      margin-top: 0;
      h2 {
        color: #4e7954;
      }
    }
    .gmaps-link {
      text-align: center;
      max-width: 100%;
      padding-top: 15px;
      margin-bottom: 0;
      .btn {
        border: 1px #4e7954 solid;
      }
    }
    .info-panel {
      background-image: url(../assets/images/location-info-bg.png);
      background-size: 100%;
      background-position: top;
      background-repeat: no-repeat;
      padding: 60px 50px 20px 50px;
      h4 {
        color: #bcd2a6;
        font-weight: 500;
      }
      p,
      table {
        color: white;
        margin-top: 5px;
        max-width: 250px;
        td {
          padding-right: 15px;
          p {
            margin: 0;
          }
        }
      }
      p.note {
        font-size: 0.7em;
        opacity: 0.8;
      }
    }
  }
  .pre-footer {
    h5 {
      margin-bottom: 20px;
      font-size: 0.8em;
    }
    h4 {
      font-size: 1.2em;
      font-weight: 500;
      margin-top:15px;
      span {
        color: #c0fd01;
      }
    }
  }
  .share {
    img {
      width: 45px;
      margin: 20px 7px;
    }
  }

  .desktop-hero-img {
    display: none;
  }
  .desktop-body-footer {
    display: none;
  }
  @media only screen and (min-width: 600px) {
    .hero-text.about-panel {
      position: absolute;
      top: 150px;
      margin-left: 150px;
    }

    .tile-lower {
      display: none;
    }
    .base-images {
      display: none;
    }
    .desktop-hero-img {
      display: block;
      float: left;
      width: 40%;
    }

    .content {
      width: 40%;
      margin-left: 150px;
      clear: both;
    }
    .content-break {
      padding-top: 50px;
    }
    .content-intro {
      padding-top: 100px;
      margin-right: 150px;
      margin-left: auto;
      clear: none;
    }
    .desktop-body-footer {
      display: block;
      margin-top: -20%;
      margin-bottom: -8px;
      text-align: right;
      img {
        width: 40%;
      }
    }

    .info {
      display: flex;
      margin-bottom: 80px;
      .gmaps-link {
        text-align: left;
      }
      .info-panel {
        background-image: url(../assets/images/location-info-bg-lg.png);
        margin-right: 150px;
        min-width: 420px;
        padding: 50px 50px 20px 80px;
        margin-top: 80px;
        p {
          max-width: 350px;
        }
      }
    }
  }
  .base-images {
    img {
      display: block;
      margin-top: 55px;
      margin-bottom: -5px;
    }
  }
  .desktop-pictures,
  .desktop-picture {
    display: none;
  }
  @media only screen and (min-width: 600px) {
    .desktop-pictures,
    .desktop-picture {
      display: block;
    }
    .desktop-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      clear: both;
      .desktop-text {
        width: 40%;
        padding-right: 150px;
        .content {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
      .desktop-pictures {
        width: 40%;
        margin-top: -100px;
        img {
          width: 100%;
        }
        img:first-child {
          margin-bottom: 150px;
        }
      }
    }
  }
}
</style>

<script>
import HornimanMuseum from "@/components/locations/HornimanMuseum.vue";
import Barbican from "@/components/locations/Barbican.vue";
import Culpeper from "@/components/locations/Culpeper.vue";
import Kyoto from "@/components/locations/Kyoto.vue";
import Isabella from "@/components/locations/Isabella.vue";
import HillyFields from "@/components/locations/HillyFields.vue";
import CrystalPalace from "@/components/locations/CrystalPalace.vue";
import Goldershill from "@/components/locations/Goldershill.vue";
import Wormwood from "@/components/locations/Wormwood.vue";
import NorthGreenwich from "@/components/locations/NorthGreenwich.vue";
import OsterleyPark from "@/components/locations/OsterleyPark.vue";
import WoodberryWetlands from "@/components/locations/WoodberryWetlands.vue";
import Haggerston from "@/components/locations/Haggerston.vue";
import BoilerGarden from "@/components/locations/BoilerGarden.vue";
import GaiasGarden from "@/components/locations/GaiasGarden.vue";

import DownArrow from "@/components/DownArrow.vue";

export default {
  name: "Location",
  components: {
    HornimanMuseum,
    Barbican,
    Culpeper,
    Kyoto,
    Isabella,
    HillyFields,
    CrystalPalace,
    Goldershill,
    Wormwood,
    NorthGreenwich,
    OsterleyPark,
    WoodberryWetlands,
    Haggerston,
    BoilerGarden,
    GaiasGarden,
    DownArrow,
  },
  inject: ["menuControls", "tabControls", "locationControls"],
  props: {
    slug: String,
    categoryId: Number,
    locationId: Number,
  },
  mounted: function () {
    this.menuControls.open();
    this.tabControls.set(1);
    this.currentLocation = this.locations[this.$route.params.slug];
    this.locationControls.set(this.currentLocation);
    this.nextLocation = this.getItem(this.$route.params.slug, +1);
    this.previousLocation = this.getItem(this.$route.params.slug, -1);
  },
  beforeRouteLeave: function (to, from, next) {
    this.menuControls.close();
    next();
  },
  watch: {
    "$route.params.slug": function () {
      //fixes intermittent bug where loading stops half way up the page on url change
      setTimeout(function(){ 
      window.scrollTo(0,0);
      },100);
      this.menuControls.open();
      this.tabControls.set(1);
      this.currentLocation = this.locations[this.$route.params.slug];
      this.locationControls.set(this.currentLocation);
      this.nextLocation = this.getItem(this.$route.params.slug, +1);
      this.previousLocation = this.getItem(this.$route.params.slug, -1);
    },
  },
  data() {
    return {
      currentLocation: {},
      nextLocation: false,
      previousLocation: false,
      locations: {
          "07-crystalpalace": {
          slug: "07-crystalpalace",
          title: "Crystal Palace Park",
          description: "A ‘pleasure ground’ in the heart of South London",
          category: "Gathering",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fsession-sounds%2F",
          address: "Thicket Rd, London, SE19 2GA",
          tube_stops: "Crystal Palace, Penge West, Anerley",
          opening_times:
            '<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> <br /> <p>Bank Holidays</p> </td> <td> <p>Open 7:30am&nbsp;</p> <p>Open 7:30am&nbsp;</p> <p>Open 7:30am&nbsp;</p> <p>Open 7:30am&nbsp;</p> <p>Open 7:30am&nbsp;</p> <p>Open 9am&nbsp;</p> <p>Open 9&nbsp;</p> <br /> <p>Open 9am&nbsp;</p> </td> </tr> </tbody> </table> <p class="note">Closing times can vary between 4.30pm-9.30pm depending on the time of year. </p>',
          walkingRoute: "https://tfl.gov.uk/modes/walking/green-chain-walk",
        },
        "08-goldershill": {
          slug: "08-goldershill",
          title: "Golders Hill Park",
          description: "A secluded corner of bustling Hampstead Heath",
          category: "Gathering",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Ffeverish-fantazmagoria-curated-by-dangelo%2F",
          address: "North End Way, London, NW3 7HE",
          tube_stops: "Golders Green, Hampstead",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>7:30am-9pm&nbsp;</p> <p>7:30am-9pm</p> <p>7:30am-9pm</p> <p>7:30am-9pm</p> <p>7:30am-9pm</p> <p>7:30am-9pm</p> <p>7:30am-9pm</p> </td> </tr> </tbody> </table> ",
          walkingRoute: "https://tfl.gov.uk/modes/walking/capital-ring",
        },
        "09-wormwood": {
          slug: "09-wormwood",
          title: "Wormwood Scrubs",
          description: "West London’s wide-open space",
          category: "Gathering",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fbritish-beats-bars%2F",
          address: "Scrubs Lane, White City, London, W12 0DF",
          tube_stops: "East Acton, Ladbroke Grove, Kensal Green",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> </td> </tr> </tbody> </table>",
          walkingRoute: "https://tfl.gov.uk/modes/walking/jubilee-greenway",
        },
        "10-northgreenwich": {
          slug: "10-northgreenwich",
          title: "North Greenwich Riverside",
          description: "Where the busy city meets the gentle river",
          category: "Exercise",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fthe-sound-of-philadelphia-hosted-by-kenny-gamble%2F",
          address: "London, SE10 0XX",
          tube_stops: "North Greenwich",
          opening_times:
            "<table> <tbody> <tr> <td> Monday</p> Tuesday</p> Wednesday</p> Thursday&nbsp;</p> Friday</p> Saturday</p> Sunday</p> </td> <td> 24hrs</p> 24hrs</p> 24hrs</p> 24hrs</p> 24hrs</p> 24hrs</p> 24hrs</p> </td> </tr> </tbody> </table>",
          walkingRoute: "https://tfl.gov.uk/modes/walking/thames-path",
        },
        "11-osterleypark": {
          slug: "11-osterleypark",
          title: "Osterley Park",
          description: "A vast park centred around a Grade 1 listed mansion",
          category: "Exercise",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fradio-chemical-curated-by-the-chemical-brothers%2F",
          address: "Jersey Road, Isleworth, Middlesex, TW7 4RB",
          tube_stops: "Osterley",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>8am-6pm&nbsp;</p> <p>8am-6pm&nbsp;</p> <p>8am-6pm&nbsp;</p> <p>8am-6pm&nbsp;</p> <p>8am-6pm&nbsp;</p> <p>8am-6pm&nbsp;</p> <p>8am-6pm&nbsp;</p> </td> </tr> </tbody> </table> ",
          walkingRoute: "https://tfl.gov.uk/modes/walking/capital-ring",
        },
        "12-woodberrywetlands": {
          slug: "12-woodberrywetlands",
          title: "Woodberry Wetlands",
          description: "A North East London’s wildflower wilderness",
          category: "Exercise",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fpindrop-electronic%2F",
          address:
            "Woodberry Grove, New River Path, Woodberry Down, London, N16 5HQ",
          tube_stops: "Manor House, Stamford Hill, Stoke Newington",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>9am-4.30pm&nbsp;</p> <p>9am-4.30pm&nbsp;</p> <p>9am-4.30pm&nbsp;</p> <p>9am-4.30pm&nbsp;</p> <p>9am-4.30pm&nbsp;</p> <p>9am-4.30pm&nbsp;</p> <p>9am-4.30pm&nbsp;</p> </td> </tr> </tbody> </table>",
          walkingRoute: "https://tfl.gov.uk/modes/walking/capital-ring",
        },
        "04-kyoto": {
          slug: "04-kyoto",
          title: "Kyoto Garden",
          description:
            "A tranquil, Japanese garden in the heart of West London",
          category: "Meditating",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fthe-inner-now%2F",
          address: "Holland park, Holland Park Ave, London, W11 4UA",
          tube_stops: "Holland Park, Shepherd’s Bush, Kensington Olympia",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>7:30am-8pm</p> <p>7:30am-8pm</p> <p>7:30am-8pm</p> <p>7:30am-8pm</p> <p>7:30am-8pm</p> <p>7:30am-8pm</p> <p>7:30am-8pm</p> </td> </tr> </tbody> </table>",
          walkingRoute: "https://tfl.gov.uk/modes/walking/jubilee-greenway",
        },
        "05-isabella": {
          slug: "05-isabella",
          title: "Isabella Plantation",
          description: "Richmond Park’s exotic hidden corner",
          category: "Meditating",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Focean-sounds%2F",
          address: "Richmond Park, Richmond, TW10 5HS",
          opening_times:
            '<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> </td> </tr> </tbody> </table> <p class="note">Except during the six week deer culls from November to early December and February to early March. During these months, pedestrian gates open at 7:30am and close at 8:00pm.</p>',
          tube_stops: "Richmond, Hampton Wick, New Malden",
          walkingRoute: "https://tfl.gov.uk/modes/walking/capital-ring",
        },
        "06-hillyfields": {
          slug: "06-hillyfields",
          title: "Hilly Fields",
          description: "A tree-lined retreat with a view",
          category: "Meditating",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fmore-minimal%2F",
          address: "London, SE4 1LD",
          tube_stops: "Brockley, Ladywell, Lewisham ",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> <p>24hrs</p> </td> </tr> </tbody> </table> ",
          walkingRoute: "https://tfl.gov.uk/modes/walking/green-chain-walk",
        },
        "01-horniman-museum": {
          slug: "01-horniman-museum",
          title: "The Horniman Museum and Garden",
          description: "A unique, surprising spot with a view",
          category: "Learning",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fquiet-classical%2F",
          address: "100 London Rd, London, SE23 3PQ",
          tube_stops: "Forest Hill, Honor Oak Park",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> <br /> <p>Bank Holidays</p> </td> <td> <p>7:15am-8:50pm</p> <p>7:15am-8:50pm</p> <p>7:15am-8:50pm</p> <p>7:15am-8:50pm</p> <p>7:15am-8:50pm</p> <p>7:15am-8:50pm</p> <p>8am-8:50pm</p> <br /> <p>8am-8:50pm</p> </td> </tr> </tbody> </table>",
          walkingRoute: "https://tfl.gov.uk/modes/walking/green-chain-walk",
        },
        "02-barbican": {
          slug: "02-barbican",
          title: "Barbican Conservatory",
          description: "A tropical oasis hidden in a world-famous art centre",
          category: "Learning",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Ffull-symphony%2F",
          address: "Silk St, London, EC2Y 8DS",
          tube_stops: "Barbican, Moorgate, Farringdon",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>12pm-6:30pm</p> <p>12pm-6:30pm</p> <p>12pm-6:30pm</p> <p>12pm-6:30pm</p> <p>12pm-9:30pm</p> <p>12pm-9:30pm</p> <p>12pm-6:30pm</p> </td> </tr> </tbody> </table>",
          walkingRoute: "https://tfl.gov.uk/modes/walking/jubilee-walkway",
        },
        "03-culpeper": {
          slug: "03-culpeper",
          title: "Culpeper Community Garden",
          description: "A calm retreat in the middle of busy Islington",
          category: "Learning",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fmellow-morning%2F",
          address: "1 Cloudesley Rd, London, N1 0EJ",
          tube_stops: "Angel, Kings Cross, Essex Road",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>10am-6pm</p> <p>10am-6pm</p> <p>10am-6pm</p> <p>10am-6pm</p> <p>10am-6pm</p> <p>Closed</p> <p>Closed</p> </td> </tr> </tbody> </table> ",
          walkingRoute: "https://tfl.gov.uk/modes/walking/jubilee-greenway",
        },
        "13-haggerston": {
          slug: "13-haggerston",
          title: "The Haggerston Orchard",
          description: "Nurturing community spirit in East London",
          category: "Volunteering",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Falways-read-the-label-factory-records%2F",
          address: "Haggerston Park, London, E2 8QH",
          tube_stops: "Cambridge Heath, Hoxton, Bethnal Green",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>8am-Dusk</p> <p>8am-Dusk</p> <p>8am-Dusk</p> <p>8am-Dusk</p> <p>8am-Dusk</p> <p>8am-Dusk</p> <p>8am-Dusk&nbsp;</p> </td> </tr> </tbody> </table> ",
          walkingRoute: "https://tfl.gov.uk/modes/walking/jubilee-greenway",
        },
        "14-boilergarden": {
          slug: "14-boilergarden",
          title: "The Boiler Garden",
          description: "A new biodiversity project on a South London estate",
          category: "Volunteering",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fchill-beats%2F",
          address: "Myatt’s Field South, 2a Calais St, Vassal, London, SE5 9LP",
          tube_stops: "Oval, Loughborough Junction, Denmark Hill",
          opening_times:
            "<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>7am-Dusk</p> <p>7am-Dusk</p> <p>7am-Dusk</p> <p>7am-Dusk</p> <p>7am-Dusk</p> <p>7am-Dusk</p> <p>7am-Dusk</p> </td> </tr> </tbody> </table>",
          walkingRoute: "https://tfl.gov.uk/modes/walking/thames-path",
        },
        "15-gaiasgarden": {
          slug: "15-gaiasgarden",
          title: "Gaia's Garden",
          description: "Community gardening with sustainability at its heart",
          category: "Volunteering",
          embed:
            "https://www.mixcloud.com/widget/iframe/?hide_cover=0&feed=%2Fsonos%2Fahead-of-their-time-feat-mykki-blanco%2F",
          address: "65 Holborn Viaduct, London, EC1A 2FD ",
          tube_stops: "St Pauls, Chancery Lane, Ludgate Hill ",
          opening_times:
            '<table> <tbody> <tr> <td> <p>Monday</p> <p>Tuesday</p> <p>Wednesday</p> <p>Thursday&nbsp;</p> <p>Friday</p> <p>Saturday</p> <p>Sunday</p> </td> <td> <p>9am-9pm</p> <p>9am-9pm</p> <p>9am-9pm</p> <p>9am-9pm</p> <p>9am-9pm</p> <p>9am-9pm</p> <p>9am-9pm</p> </td> </tr> </tbody> </table> <p class="note">Opening times until September only</p> ',
          walkingRoute: "https://tfl.gov.uk/modes/walking/jubilee-walkway",
        },
      },
    };
  },
  methods: {
    getItem(key, i) {
      var keys = Object.keys(this.locations).sort(function (a, b) {
        return a - b;
      });
      var index = keys.indexOf(key);
      if ((i == -1 && index > 0) || (i == 1 && index < keys.length - 1)) {
        index = index + i;
      }
      console.log(index);
      return this.locations[keys[index]];
    },
    getImgUrl(slug, asset) {
      if (slug != undefined) {
        return require("../assets/images/locations/" +
          slug +
          "/" +
          asset +
          ".png");
      }
    },
    getImgUrlLarge(slug, asset) {
      if (slug != undefined) {
        return require("../assets/images/locations/" +
          slug +
          "/" +
          asset +
          "-lg.png");
      }
    },
    getMapAsset(slug) {
      if (slug != undefined) {
        return require("../assets/images/locations/" + slug + "/map.svg");
      }
    },
  },
};
</script>
